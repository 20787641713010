.navbar-container {
  display: flex;
  position: relative; /* if sticky navbar -> change this to 'sticky' */
  flex-direction: row;
  background-color: var(--color-main-light);
  color: var(--color-main-dark);
  max-width: 100%;
  padding: 4% 7%;
  justify-content: space-between;
  top: 0;
  z-index: 10;
  transition: padding 0.3s ease, background-color 0.3s ease;
}


.navbar-container.smaller {
  padding: 2% 7%;
  background-color: var(--color-main-light-detail); 
  transition: padding 0.3s ease, background-color 0.3s ease;
}

.navbar-container-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-main-light);
  z-index: 10;
}

.navbar-container-background.smaller {
  background-color: var(--color-main-light-detail); 
  transition: padding 0.3s ease, background-color 0.3s ease;
}

nav {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  width: 70%;
  color: var(--color-main-dark);
  text-align: right;
  z-index: 10;
}

.navbar-container a {
  text-decoration: none;
  color: black;
}

nav .active {
  opacity: 0.5;
}

nav a:hover {
  opacity: 0.5;
}

nav ul {
  display: flex;
  margin: 0;
}

nav li {
  list-style-type: none;
  letter-spacing: 1px; 
}

.site-title {
  width: 30%;
  white-space: nowrap;
  letter-spacing: 2px; 
  z-index: 10;
}

nav li {
  margin: 0 0 0 2rem;
}

.hamburger-menu-container, .hamburger-menu {
  display: none;
}

.hamburger-menu-container, 
.hamburger-menu-container.smaller, 
.navbar-container-background, 
.navbar-container-background.smaller {
  transition: all 0.3s ease;
}

@media screen and (max-width: 700px) and (min-width: 500px) {
  nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: fit-content;
    z-index: 5;
  }

  nav .active:hover {
    opacity: 0.5;
  }

  .hamburger-menu-container {
    display: flex;
    position: absolute;
    width: 30px;
    height: 30px;
    margin: 4% 7%;
    top: 0;
    right: 0;
    cursor: pointer;
    z-index: 10;
    transition: margin 0.3s ease;
  }

  .hamburger-menu-container.smaller {
    margin: 2% 7%;
    transition: margin 0.3s ease;
  }


  .hamburger-menu {
    position: absolute;
    display: block;
    margin: 14px 5px;
    top: 0;
    right: 0;
    align-items: center;
    cursor: pointer;
    z-index: 5;
    transition: all 0.3s ease-in-out;
  }

  .hamburger-menu-button {
    width: 20px;
    height: 1px;
    background: #000;
    transition: all 0.3s ease-in-out;
  }


  .hamburger-menu-button::before,
  .hamburger-menu-button::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 1px;
    background: var(--color-main-dark);
    transition: all 0.3s ease-in-out;
  }
  .hamburger-menu-button::before {
    transform: translateY(-8px);
  }
  .hamburger-menu-button::after {
    transform: translateY(8px);
  }
  .hamburger-menu.icon-open .hamburger-menu-button  {
    transform: translateX(50px);
    background: transparent;
  }

  .hamburger-menu.icon-open  {
    transform: translateX(-50px);
  }

  .hamburger-menu.icon-open .hamburger-menu-button::before {
    transform: rotate(45deg) translate(0, 0);
    height: 1px;
  }
  .hamburger-menu.icon-open .hamburger-menu-button::after {
    transform: rotate(-45deg) translate(0, 0);
    height: 1px;
  }

  nav ul li, nav a {
    margin: 0;
  }

  nav ul {
    display: block;
    position: absolute;
    top: -200px;
    right: 7%;
    flex-direction: column;
    width: fit-content;
    margin-top: 50px;
    background: var(--color-main-light);
    padding: 0;
    transition: all 0.3s ease-out;
  }

  nav ul li {
    width: 100%;
    text-align: right;
    opacity: 1;
    padding: 0.8rem;
    padding-left: 1.8rem;
  }

  nav ul li:hover {
    background-color: var(--color-main-light-detail);
    color: var(--color-main-dark);
    opacity: 1;
    width: 100%;

  }


  nav a:hover {
    opacity: 1
  }

  nav .active:hover {
    color: var(--color-main-dark-detail);
  }

  nav ul.open {
    display: flex;
    top: 30px;
    right: 7%;
    opacity: 1;
    transition: all 0.3s ease-in-out;
    z-index: 1;
    background-color: var(--color-main-light);
  }

  nav ul.smaller {
    top: 0px;
    transition: all 0.3s ease-in-out;
  }

}

@media screen and (max-width: 500px) {
  
  .navbar-container {
    position: relative;
    flex-direction: column;
    color: var(--color-main-dark);
    max-width: 100%;
    padding: 4% 7%;
    justify-content: space-between;
  }


  nav {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    width: 100%;
    opacity: 0; 
    visibility: hidden;
    transition: opacity 0.3s ease-out, visibility 0.3s ease-out;
    z-index: 5;
  }

  nav.open {
    opacity: 1; 
    visibility: visible; 
    transition: opacity 0.3s ease-in, visibility 0.3s ease-in;
  }
  
  .hamburger-menu-container {
    display: flex;
    position: absolute;
    width: 30px;
    height: 30px;
    margin: 4% 7%;
    top: 0;
    right: 0;
    cursor: pointer;
    z-index: 10;
  }


  .hamburger-menu {
    position: absolute;
    display: block;
    margin: 14px 5px;
    top: 0;
    right: 0;
    align-items: center;
    cursor: pointer;
    z-index: 5;
    width: fit-content;
    height: fit-content;
    transition: all 0.3s ease-in-out;
  }

  .hamburger-menu-button {
    width: 20px;
    height: 1px;
    background: var(--color-main-dark);
    transition: all 0.3s ease-in-out;
  }


  .hamburger-menu-button::before,
  .hamburger-menu-button::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 1px;
    background: var(--color-main-dark);
    transition: all 0.3s ease-in-out;
  }
  .hamburger-menu-button::before {
    transform: translateY(-8px);
  }
  .hamburger-menu-button::after {
    transform: translateY(8px);
  }
  .hamburger-menu.icon-open .hamburger-menu-button  {
    transform: translateX(50px);
    background: transparent;
  }

  .hamburger-menu.icon-open  {
    transform: translateX(-50px);
  }

  .hamburger-menu.icon-open .hamburger-menu-button::before {
    transform: rotate(45deg) translate(0, 0);
    height: 1px;
  }
  .hamburger-menu.icon-open .hamburger-menu-button::after {
    transform: rotate(-45deg) translate(0, 0);
    height: 1px;
  }


  nav ul {
    position: relative;
    top:-200px;
    flex-direction: column;
    width: 100%;
    padding: 0;
    transition: all 0.3s ease-out;
    background: var(--color-main-light);
  }

  nav ul li {
    width: 100%;
    text-align: right;
    padding: 1rem;
    text-align: center;
    margin: 0;
  }

  nav ul li a {
    margin-left: 0;
  }

  nav ul li:hover {
    background-color: var(--color-main-light-detail);
    color: var(--color-main-dark);
  }

  nav ul li a:hover {
    color: var(--color-main-dark);
    opacity: 1;
  }

  nav a:hover {
    opacity: 1
  }

  nav .active:hover {
    opacity: 0.5;
  }

  nav ul.open {
    top: 0;
    left:0;
    transition: all 0.3s ease-in;
    height: fit-content;
    padding: 0;
    margin: 0;
  }

}


@media screen and (min-width: 800px) {

  .navbar-container {
    width: 1200px;
    padding: 4% 7%;
  }
}
  
