.projects-container {
  max-width: 850px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;

}

.projects-container h2 {
  text-align: center;
  min-width: 225px;
}

.featured-project h3 {
  text-align: left;
  margin: 0;
}

.featured-project {
  display: flex;
  flex-direction: row;
  margin: 25px 0;
}

.project-image {
  position: relative;
  display: flex;
  width: 60%;
  margin: 0 25px 0 0;
  align-items: center;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.project-image img {
  width: 100%;
  transition: opacity 0.6s;
}

.project-image .project-image-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  color: var(--color-main-light);
  background-color: rgba(var(--accent-color-values), 0.5);
  text-shadow: #fff 1px 0 20px;
  transition: opacity 0.6s;
}

.project-image:hover .project-image-text {
  opacity: 1;
}

.project-image .project-image-text h2 {
  color: black;
  margin: 0;
}

.project-text {
  width: 50%;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.project-text p.read-more-link {
  width: fit-content;
  height: fit-content;
  margin: 0;
}

.project-text p.read-more-link a {
  color: inherit;
}

.project-text p.read-more-link span {
  display: inline-block;
  transform: translateX(20%);
  transition: 0.25s;
}

.project-text p.read-more-link:hover span {
  transform: translateX(40%);
}

.projects-container .featured-project .project-text h3,
.projects-container .featured-project .project-text p,
.projects-container .featured-project .project-text a {
  margin: 0.5rem 0;
}



/* @media screen and (max-width: 900px) and (min-width: 575px) {
  .projects-container {
    max-width: 600px;
  }
  
} */


@media screen and (max-width: 575px) {


  .featured-project {
    flex-direction: column;
  }

  .project-image {
    padding: 0;
    width: 75%;
    min-width: 225px;
  }

  .project-image,
  .project-text {
    margin: auto;
  }

  .project-text {
    margin-top: 0.5em;
    width: 75%;
    min-width: 225px;
  }

  .projects-container .featured-project .project-text h3,
  .projects-container .featured-project .project-text p {
    text-align: center;
    margin: 0.5rem auto;
  }
}
