.easter-egg {
    width: 2px;
    transition: transform 10s, filter 0.3s;
    filter: grayscale(100%);
  }
  
  .easter-egg:hover {
    transform: scale(10); 
    filter: grayscale(0%);
  }
  