.animation-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    /* height: 100vh; */
  }
  
  #phaser-container {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
  
  .animation-page {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    padding: 20px;
    overflow: hidden;
  }

  
  h1 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .back-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #ff5733;
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 1rem;
  }
  