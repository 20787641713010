/* Y2K Aesthetic for the Quiz */
.quiz-container {
    text-align: center;
    background: linear-gradient(45deg, #ff00ff, #ffccff, #ff99cc);
    padding: 50px;
    min-height: 100vh;
    font-family: "Comic Sans MS", cursive, sans-serif;
    color: #fff;
    animation: backgroundGlow 5s infinite alternate;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
}

@keyframes backgroundGlow {
    0% { filter: brightness(1); }
    100% { filter: brightness(1.2); }
}

.quiz-title {
    font-size: 3rem;
    text-shadow: 0 0 10px #ff00ff, 0 0 20px #ff33ff;
    margin-bottom: 30px;
    animation: glowText 1.5s infinite alternate;
}

@keyframes glowText {
    0% { text-shadow: 0 0 5px #ff00ff, 0 0 10px #ff33ff; }
    100% { text-shadow: 0 0 10px #ff00ff, 0 0 20px #ff33ff; }
}

.quiz-layout {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    max-width: 900px;
}

.quiz-image {
    width: 150px;
    height: auto;
    transition: all 0.5s ease;
}

.highlight {
    transform: scale(1.5);
    filter: drop-shadow(0 0 15px pink);
}

.dimmed {
    opacity: 0.5;
}

.quiz-question {
    background: rgba(255, 255, 255, 0.2);
    border: 2px solid #ff33ff;
    border-radius: 15px;
    padding: 20px;
    width: 50%;
    text-align: center;
    box-shadow: 0 0 10px #ff66ff;
    transition: transform 0.2s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.quiz-question:hover {
    transform: scale(1.05);
}

.quiz-buttons button {
    background: #ff33ff;
    border: none;
    padding: 10px 20px;
    font-size: 1.2rem;
    color: white;
    margin: 10px;
    border-radius: 10px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    box-shadow: 0 0 10px #ff33ff;
    transition: all 0.3s ease;
}

.quiz-buttons button:hover {
    background: #ff66ff;
    transform: scale(1.1);
}

.quiz-result {
    margin-top: 30px;
}

.quiz-marquee {
    font-size: 2rem;
    font-weight: bold;
    color: hotpink;
    background: black;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 0 10px hotpink;
}

@keyframes sparkle {
    0% { opacity: 0; transform: scale(1); }
    50% { opacity: 1; transform: scale(1.5); }
    100% { opacity: 0; transform: scale(1); }
}

.sparkle {
    position: absolute;
    width: 10px;
    height: 10px;
    background: white;
    box-shadow: 0 0 10px white;
    border-radius: 50%;
    animation: sparkle 1.5s infinite;
}
