.about-container {
  display: flex;
  flex-direction: column;
  justify-content: center; 
  width: 100%;
  margin-bottom: 20px; 
  max-width: 850px;
  margin: auto;
}

.about-main-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.about-text {
  flex: 1;
  width: 60%;
  margin-right: 5px;
  min-width: 250px;
}

.about-text span {
  display: inline-block;
}

.about-text li {
  list-style-type: none;
}

.about-text .about-contact a {
  transition: all 0.3s ease-out;
 }

.about-text .about-contact a:hover {
 opacity: 0.5;
 color: var(--color-main-dark);
 transition: all 0.3s ease-out;
}
 
.about-text .rough-annotation {
  opacity: 0.4;
}


.about-text .about-buttons {
  display: inline-flex;
  margin: 10px 0 0 0;
}

.about-picture {
  width: 40%;
  display: flex;
  justify-content: flex-end;
  padding-left: 10px
}

.about-picture img {
  width: 100%;
  max-width: 275px;
  box-shadow: 0 4px 8px 0 var(--color-main-dark-detail), 0 6px 20px 0 var(--color-main-dark-detail);
  border-radius: 15px;
}

.currently-reading-section {
  width: 100%; 
  margin-top: 50px; 
}


@media screen and (max-width: 700px) {
  .about-container {
    flex-direction: column;
    justify-content: center;
  }

  .about-container .about-text {
    margin-right: 0;
    width: 100%;
  }

  .about-container .about-picture {
    display: none;
    width: 100%;
    margin: 40px auto;
    justify-content: center;
  }

  .about-container .about-buttons {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }


  .about-container h2 {
    text-align: center;
    font-size: 1rem;
  }
}


@media screen and (max-width: 370px) {
  .about-container .about-buttons {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
  .about-container .about-buttons button {
    margin: 8px auto;
  }
  .about-text button#linked {
    margin-right: 0;
  }
}