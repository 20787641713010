.app {
  margin: auto;
  width: 100%;
  font-size: 1rem;
  justify-content: center;
  max-width: 100%;
  padding-bottom: 50px;
}


.main-container {
  margin: 30px 7%;
  height: fit-content;
}

h2 {
  color: var(--color-main-dark);
  margin-top: 0;
}

h3 {
  color: var(--color-main-dark);
  font-size: 0.8rem;
}

p {
  color: var(--color-main-dark);
  font-size: 0.8rem;
}

a {
  text-decoration: none;
  color: inherit;
}

.link-styles {
  overflow: hidden;
}

.home-div {
  margin-bottom: 75px;
}


button {
  width: fit-content;
  cursor: pointer;
  border: solid 1px var(--color-main-dark);
  border-radius: 5px;
  padding: 10px 25px;
  margin: 10px auto 0 auto;
  transition: all 0.3s ease-in;
}

button:hover {
  transition: all 0.3s ease-in;
}

button.dark {
  color: var(--color-main-light);
  background-color: var(--color-main-dark);
  margin-right: 10px;
}

button.dark:hover {
  color: var(--color-main-dark);
  background-color: var(--color-main-accent);
}

button.light {
  color: black;
  background-color: var(--color-main-light);
}

button.light:hover {
  background-color: var(--color-main-accent);
  color: var(--color-main-dark);
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}


@media screen and (max-width: 500px) {

  h2 {
    font-size: 1.2rem;
  }


}

@media screen and (min-width: 800px) {
  .app {
    max-width: 1200px;
    justify-content: center;
  }
}
  