.contact-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.contact-container h2 {
  text-align: center;
}

.contact-container form {
  width: 300px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto;
}

.contact-container form input, .contact-container form textarea  {
  font-size: 0.8rem;
  margin: 5px 0;
  border-radius: 5px;
  border: 1px solid var(--color-main-dark-detail);
  padding: 5px 7px;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  
}

.contact-container form textarea  {
    resize: none;
    height: 5rem;
  }


.success-message {
  font-size: 1rem;
  text-align: center;
  opacity: 1; 
  overflow: hidden; 
  transition: max-height 1s ease-in-out; 
  animation: fadeIn 1s;
  margin-top: 1rem;
}

.error-message {
  color: red;
}


.linkedInButton {
  margin: 0 auto;
  animation: fadeIn 2s; 
}


button#contact-form {
  margin: 5px auto;
}

.linkedInButton button#linkedButton {
  background-color: var(--color-main-light);
  color: var(--color-main-dark);
  border: 1px solid var(--color-main-dark);
  transition: all 0.3s ease-out;
}

.linkedInButton button#linkedButton:hover {
  background-color: var(--color-main-accent);
  transition: all 0.3s ease-in;
}


.dark.sending {
  background-color: var(--color-main-accent); 
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
