.secret-world body {
    background: radial-gradient(circle at center, #000080, black 70%);
    height: 100vh;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.secret-world-container {
    width: 80%; 
    max-width: 1200px;
}

.secret-world-image {
    width: 100%;
    height: auto;
    display: block; 
}


.secret-button-container {
    margin-top: 20px;
  }
  
  .secret-quiz-button {
    display: inline-block;
    background: black;
    color: pink;
    font-size: 1.5rem;
    font-weight: bold;
    padding: 15px 30px;
    border: 2px solid pink;
    border-radius: 8px;
    text-decoration: none;
    box-shadow: 0 0 10px pink;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
    animation: glow 1.5s infinite alternate;
  }
  
  .secret-quiz-button:hover {
    background: hotpink;
    color: black;
    box-shadow: 0 0 20px hotpink;
  }
  
  @keyframes glow {
    0% { text-shadow: 0 0 5px pink; }
    100% { text-shadow: 0 0 15px pink; }
  }